<template>
    <auth-common-form form-type="set-password">
        <template #title>{{ $t('AUTH.SET.TITLE') }}</template>
        <template #subtitle>{{ $t('AUTH.SET.DESC') }}</template>
    </auth-common-form>
</template>

<script>
import AuthCommonForm from '../components/AuthCommonForm';

export default {
    name: 'SetPassword',
    components: {
        AuthCommonForm,
    },
};
</script>

<template>
    <form
        class="form"
        novalidate="novalidate"
        id="st_reset_form"
    >
        <div class="form-group">
            <label>
                {{ $t('AUTH.INPUT.OLD_PASSWORD') }}</label
            >
            <div id="old-password-input-group">
                <input
                    class="form-control form-control-solid h-auto py-4 px-6 rounded-sm"
                    type="password"
                    name="oldPassword"
                    ref="oldPassword"
                    autocomplete="off"
                />
            </div>
        </div>
        <div class="form-group">
            <label>
                {{ $t('AUTH.INPUT.NEW_PASSWORD') }}</label
            >
            <div id="password-input-group">
                <input
                    class="form-control form-control-solid h-auto py-4 px-6 rounded-sm"
                    type="password"
                    name="password"
                    ref="password"
                    autocomplete="off"
                />
                <b-form-text id="input-live-help">{{ passwordFieldHelpTooltip }}</b-form-text>
            </div>
        </div>

        <div class="form-group last">
            <label>
                {{ $t('AUTH.INPUT.CONFIRM_PASSWORD') }}</label
            >
            <div id="confirm-password-input-group">
                <input
                    class="form-control form-control-solid h-auto py-4 px-6 rounded-sm"
                    type="password"
                    name="confirmPassword"
                    ref="confirmPassword"
                    autocomplete="off"
                />
            </div>
        </div>
        <div class="form-group d-flex flex-row pb-lg-0">
            <div class="col-6 pl-0">
                <st-button
                    block
                    :spinner="isLoadingResetPassword"
                    customClass="font-size-h4"
                    :callback="submit"
                >
                    {{ $t('AUTH.CHANGE_PASSWORD.BUTTON') }}
                </st-button>
            </div>
            <div class="col-6 pr-0">
                <router-link :to="{ path: '/auth/signin' }">
                    <button
                        type="button"
                        id="st_cancel_button"
                        class="btn btn-outline-primary font-size-h4 w-100"
                    >
                        {{ $t('AUTH.GENERAL.CANCEL_BUTTON') }}
                    </button>
                </router-link>
            </div>
        </div>
    </form>
</template>
<script>
    import formValidation from '@/assets/plugins/formvalidation/dist/es6/core/Core';

    // FormValidation plugins
    import Trigger from '@/assets/plugins/formvalidation/dist/es6/plugins/Trigger';
    import Bootstrap from '@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap';
    import SubmitButton from '@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton';

    import KTUtil from '@/assets/js/components/util';

    import { mapActions, mapGetters } from 'vuex';

    import { strongPassword } from '@/core/helpers/globalMethods';
    import { CHANGE_PASSWORD, LOGOUT } from '@/modules/auth/auth-store';

    export default {
        name: 'ChangePasswordForm',
        data() {
            return {
                oldPassword: '',
                password: '',
                confirmPassword: '',
                email: '',
            };
        },
        computed: {
            ...mapGetters({
                loading: 'shared/loading',
            }),
            isLoadingResetPassword() {
                return !!this.loading?.['auth/resetPassword'];
            },
            passwordFieldHelpTooltip() {
                return this.$t('AUTH.REGISTER.PASSWORD_HELP');
            }
        },
        methods: {
            ...mapActions({
                resetPassword: `auth/${CHANGE_PASSWORD}`,
                logout: `auth/${LOGOUT}`
            }),
            async submit() {
                const validate = await this.fv.validate();
                if (validate === 'Valid') {
                    const oldPassword = this.$refs.oldPassword.value;
                    const password = this.$refs.password.value;
                    const email = this.email;
                    this.resetPassword({ oldPassword, password, email })
                        .then(() => {
                            this.$router.push({ name: 'signin' });
                        });
                }
            }
        },
        mounted() {
            const reset_form = KTUtil.getById('st_reset_form');

            this.fv = formValidation(reset_form, {
                fields: {
                    oldPassword: {
                        validators: {
                            notEmpty: {
                                message: this.$t('AUTH.VALIDATION.REQUIRED')
                            },
                            checkPassword: {
                                message: this.$t('AUTH.VALIDATION.STRONG_PASSWORD')
                            }
                        }
                    },
                    password: {
                        validators: {
                            notEmpty: {
                                message: this.$t('AUTH.VALIDATION.REQUIRED')
                            },
                            checkPassword: {
                                message: this.$t('AUTH.VALIDATION.STRONG_PASSWORD')
                            }
                        }
                    },
                    confirmPassword: {
                        validators: {
                            notEmpty: {
                                message: this.$t('AUTH.VALIDATION.REQUIRED')
                            },
                            identical: {
                                compare: function() {
                                    return reset_form.querySelector('[name="password"]').value;
                                },
                                message: this.$t('AUTH.VALIDATION.CONFIRM_PASSWORD')
                            }
                        }
                    }
                },
                plugins: {
                    trigger: new Trigger(),
                    submitButton: new SubmitButton(),
                    bootstrap: new Bootstrap()
                }
            })
                .registerValidator('checkPassword', strongPassword);
        },
        created() {
            this.email = this.$route.params?.email;
        }
    };
</script>

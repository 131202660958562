<template>
    <auth-common-form form-type="forgot-password">
        <template #title>{{ $t('AUTH.FORGOT.TITLE') }}</template>
        <template #subtitle>{{ $t('AUTH.FORGOT.DESC') }}</template>
    </auth-common-form>
</template>

<script>
import AuthCommonForm from '../components/AuthCommonForm';

export default {
    name: 'ForgotPassword',
    components: {
        AuthCommonForm,
    },
};
</script>

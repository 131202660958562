<template>
    <auth-common-form
        form-type="register">
        <template #title>{{ $t('AUTH.REGISTER.TITLE') }}</template>
        <template #subtitle>{{ $t('AUTH.REGISTER.DESC') }}</template>
    </auth-common-form>
</template>

<script>
    import AuthCommonForm from '../components/AuthCommonForm';

    export default {
        name: 'Register',
        components: {
            AuthCommonForm
        },
    };
</script>

<template>
    <div>
        <Loader  v-if="isLoading" :logo="logoImage"></Loader>
    </div>
</template>
<script>
    import { mapActions } from 'vuex';
    import { AUTH_GOOGLE, GET_PROFILE } from '../auth-store';

    export default {
        name: 'AuthGoogle',
        data() {
            return {
                isLoading: true,
            };
        },
        methods: {
            ...mapActions({
                authGoogle: `auth/${AUTH_GOOGLE}`,
                getProfile: `auth/${GET_PROFILE}`
            }),
        },
        computed: {
            logoImage() {
                return `${process.env.BASE_URL}media/logos/logo-GU-CJCJ-stema.png`;
            },
        },
        created() {
            const token = this.$route.query.code;
            if (!token) {
                this.$router.push({ name: 'signin'});
                return;
            }

            this.authGoogle(token).then((data) => {
                const { isPhoneConfirmed } = data;
                this.isLoading = false;
                if (!isPhoneConfirmed) {
                    this.$router.push({ name: 'verifySMS' });
                } else {
                    this.getProfile().then(() => {
                        this.$router.push({ name: 'home' });
                    });
                }
            }).catch(() => {
                this.isLoading = false;
                this.$alert({
                    type: 'error',
                    text: 'Something went wrong!',
                    callback: () => {
                        this.$router.push({ name: 'signin' });
                    }
                });
            });
        }
    };
</script>

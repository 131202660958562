<template>
    <auth-common-form
        formType="login"
        :isSocialAuthEnabled="hasSocialAuth">
        <template #title>{{ $t('AUTH.LOGIN.TITLE') }}</template>
        <template #subtitle>{{ $t('AUTH.LOGIN.DESC') }}</template>
    </auth-common-form>
</template>

<script>
    import AuthCommonForm from '../components/AuthCommonForm';

    export default {
        name: 'Login',
        components: {
            AuthCommonForm
        },
        data() {
            return {
                hasSocialAuth: false
            };
        },
        created() {
            this.hasSocialAuth = !this.$renderInApp;
        }
    };
</script>

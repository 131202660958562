<template>
    <auth-common-form form-type="reset-password">
        <template #title>{{ $t('AUTH.RESET.TITLE') }}</template>
        <template #subtitle>{{ $t('AUTH.RESET.DESC') }}</template>
    </auth-common-form>
</template>

<script>
import AuthCommonForm from '../components/AuthCommonForm';

export default {
    name: 'ResetPassword',
    components: {
        AuthCommonForm,
    },
};
</script>

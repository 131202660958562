<template>
    <form
        class="form"
        novalidate="novalidate"
        id="st_reset_form"
    >
        <div class="form-group">
            <label>
                {{ $t('AUTH.INPUT.PASSWORD') }} *</label
            >
            <div id="password-input-group" label="" label-for="password-input-group">
                <input
                    class="form-control form-control-solid h-auto py-4 px-6 rounded-sm"
                    type="password"
                    name="password"
                    ref="password"
                    autocomplete="off"
                />
                <b-form-text id="input-live-help">{{ passwordFieldHelpTooltip }}</b-form-text>
            </div>
        </div>

        <div class="form-group last">
            <label>
                {{ $t('AUTH.INPUT.CONFIRM_PASSWORD') }} *</label
            >
            <div id="confirm-password-input-group" label="" label-for="confirm-password-input-group">
                <input
                    class="form-control form-control-solid h-auto py-4 px-6 rounded-sm"
                    type="password"
                    name="confirmPassword"
                    ref="confirmPassword"
                    autocomplete="off"
                />
            </div>
        </div>
        <div class="form-group d-flex flex-row pb-lg-0">
            <div class="col-6 pl-0">
                <button
                    v-if="formType === 'reset-password'"
                    id="st_submit_button"
                    ref="st_submit_button"
                    class="btn btn-primary font-size-h4 w-100"
                >
                    {{ $t('AUTH.RESET.BUTTON') }}
                </button>
                <button
                    v-if="formType === 'set-password'"
                    id="st_submit_button"
                    ref="st_submit_button"
                    class="btn btn-primary font-size-h4 w-100"
                >
                    {{ $t('AUTH.SET.BUTTON') }}
                </button>
            </div>
            <div class="col-6 pr-0">
                <router-link :to="{ path: '/auth/signin' }">
                    <button
                        type="button"
                        id="st_cancel_button"
                        class="btn btn-outline-primary font-size-h4 w-100"
                    >
                        {{ $t('AUTH.GENERAL.CANCEL_BUTTON') }}
                    </button>
                </router-link>
            </div>
        </div>
    </form>
</template>
<script>
    import formValidation from '@/assets/plugins/formvalidation/dist/es6/core/Core';

    // FormValidation plugins
    import Trigger from '@/assets/plugins/formvalidation/dist/es6/plugins/Trigger';
    import Bootstrap from '@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap';
    import SubmitButton from '@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton';

    import KTUtil from '@/assets/js/components/util';

    import { mapActions } from 'vuex';

    import { strongPassword } from '@/core/helpers/globalMethods';
    import { RESET_PASSWORD, LOGOUT } from '@/modules/auth/auth-store';

    export default {
        name: 'ResetPasswordForm',
        props: {
            formType: {
                type: String,
                default: 'reset-password',
            },
        },
        data() {
            return {
                password: '',
                confirmPassword: '',
                confirmationKey: null
            };
        },
        computed: {
            passwordFieldHelpTooltip() {
                return this.$t('AUTH.REGISTER.PASSWORD_HELP');
            }
        },
        methods: {
            ...mapActions({
                resetPassword: `auth/${RESET_PASSWORD}`,
                logout: `auth/${LOGOUT}`
            })
        },
        mounted() {
            const reset_form = KTUtil.getById('st_reset_form');

            this.fv = formValidation(reset_form, {
                fields: {
                    password: {
                        validators: {
                            notEmpty: {
                                message: this.$t('AUTH.VALIDATION.REQUIRED')
                            },
                            checkPassword: {
                                message: this.$t('AUTH.VALIDATION.STRONG_PASSWORD')
                            }
                        }
                    },
                    confirmPassword: {
                        validators: {
                            notEmpty: {
                                message: this.$t('AUTH.VALIDATION.REQUIRED')
                            },
                            identical: {
                                compare: function() {
                                    return reset_form.querySelector('[name="password"]').value;
                                },
                                message: this.$t('AUTH.VALIDATION.CONFIRM_PASSWORD')
                            }
                        }
                    }
                },
                plugins: {
                    trigger: new Trigger(),
                    submitButton: new SubmitButton(),
                    bootstrap: new Bootstrap()
                }
            })
                .registerValidator('checkPassword', strongPassword);

            this.fv.on('core.form.valid', () => {
                const password = this.$refs.password.value;
                
                // set spinner to submit button
                const submitButton = this.$refs['st_submit_button'];
                submitButton.classList.add('spinner', 'spinner-light', 'spinner-right');
                setTimeout(() => {
                    this.resetPassword({ password, confirmationKey: this.confirmationKey })
                        .then(() => {

                            if(this.formType === 'reset-password'){
                                this.$alert({
                                    type: 'success',
                                    text: this.$t('RESET_PASSWORD.SUCCESSFULLY_MESSAGE'),
                                    showCancelButton: false,
                                    showConfirmButton: false,
                                });
                            }
                            this.logout();
                            this.$router.push({ name: 'signin' });
                        });
                    submitButton.classList.remove(
                        'spinner',
                        'spinner-light',
                        'spinner-right'
                    );
                }, 2000);
            });
        },
        created() {
            this.confirmationKey = this.$route.query?.confirmationKey;
        }
    };
</script>

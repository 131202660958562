<template>
    <auth-common-form
        form-type="register-form"
        registration-user-type="citizen">
        <template #title>{{ $t('AUTH.REGISTER.PERSONAL_TITLE') }}</template>
        <template #subtitle>{{ $t('AUTH.REGISTER.PERSONAL_DESC') }}</template>
    </auth-common-form>
</template>

<script>
    import AuthCommonForm from '../components/AuthCommonForm';

    export default {
        name: 'RegisterPersonal',
        components: {
            AuthCommonForm
        },
    };
</script>

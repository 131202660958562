<template>
    <div class="st-auth-form">
        <div class="form">
            <div class="pb-10 pt-lg-0 pt-5">
                <h2 class="font-weight-bold mb-4">
                    <slot name="title"></slot>
                </h2>
                <h6 class="text-muted font-weight-bold">
                    <slot name="subtitle"></slot>
                </h6>
            </div>
            <register v-if="formType === 'register'" />
            <register-form v-else-if="formType === 'register-form'" :registrationUserType="registrationUserType" />
            <forgot-password-form v-else-if="formType === 'forgot-password'" />
            <reset-password-form v-else-if="formType === 'reset-password'" :formType="formType" />
            <reset-password-form v-else-if="formType === 'set-password'" :formType="formType" />
            <change-password-form v-else-if="formType === 'change-password'" />
            <login-form v-else :isRegisterEnabled="isSocialAuthEnabled" />
            <div v-if="isSocialAuthEnabled" class="mt-10">
                <google-login-button :formType="formType"/>
                <facebook-login-button :formType="formType"/>
            </div>
        </div>
    </div>
</template>

<script>
import LoginForm from './LoginForm';
import Register from './RegisterContainer';
import RegisterForm from './RegisterForm';
import ForgotPasswordForm from './ForgotPasswordForm';
import ResetPasswordForm from './ResetPasswordForm';
import ChangePasswordForm from './ChangePasswordForm';
import FacebookLoginButton from './FacebookLoginButton';
import GoogleLoginButton from './GoogleLoginButton';

export default {
    name: 'AuthCommonForm',
    components: {
        LoginForm,
        Register,
        RegisterForm,
        ForgotPasswordForm,
        ResetPasswordForm,
        FacebookLoginButton,
        GoogleLoginButton,
        ChangePasswordForm,
    },
    props: {
        formType: {
            type: String,
            default: 'login',
        },
        isSocialAuthEnabled: {
            type: Boolean,
            default: false,
        },
        registrationUserType: {
            type: String,
            default: 'citizen'
        }
    },
};
</script>

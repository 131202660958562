<template>
    <div>
        <div class="pb-lg-0 mb-5">
            <router-link :to="{ name: 'signup-personal' }">
                <button
                    type="button"
                    id="st_register_personal_button"
                    class="btn btn-primary font-size-h4 w-100"
                >
                    {{ $t('AUTH.REGISTER.BUTTON_PERSONAL') }}
                </button>
            </router-link>
        </div>
        <div class="pb-lg-0 mb-5">
            <router-link :to="{ name: 'signup-professional' }">
                <button
                    type="button"
                    id="st_register_professional_button"
                    class="btn btn-primary font-size-h4 w-100"
                >
                    {{ $t('AUTH.REGISTER.BUTTON_PROFESSIONAL') }}
                </button>
            </router-link>
        </div>
        <div class="pb-lg-0 mb-5">
            <router-link :to="{ name: 'signin' }">
                <button
                    type="button"
                    id="st_cancel_button"
                    class="btn btn-outline-primary font-size-h4 w-100"
                >
                    {{ $t('AUTH.REGISTER.HAS_ACCOUNT') }}
                </button>
            </router-link>
        </div>
    </div>
</template>
<script>
export default {
    name: 'RegisterContainer',
}
</script>

<template>
    <form class="form" novalidate="novalidate" id="st_register_form">
        <div class="form-group">
            <label>
                {{ $t('AUTH.INPUT.EMAIL') }}</label
            >
            <div id="email-input-group" label="" label-for="email-input-group">
                <input
                    class="form-control form-control-solid h-auto py-4 px-6 rounded-sm"
                    type="text"
                    name="email"
                    ref="email"
                />
            </div>
        </div>
        <div class="form-group">
            <label>
                {{ $t('AUTH.INPUT.PASSWORD') }}</label
            >
            <div id="password-input-group" label="" label-for="password-input-group">
                <input
                    class="form-control form-control-solid h-auto py-4 px-6 rounded-sm"
                    type="password"
                    name="password"
                    ref="password"
                />
                <b-form-text id="input-live-help">{{ passwordFieldHelpTooltip }}</b-form-text>
            </div>
        </div>
        <div class="form-group mb-6">
            <label>
                {{ $t('AUTH.INPUT.CONFIRM_PASSWORD') }}</label
            >
            <div id="confirm-password-input-group" label="" label-for="confirm-password-input-group">
                <input
                    class="form-control form-control-solid h-auto py-4 px-6 rounded-sm"
                    type="password"
                    name="confirmPassword"
                    ref="confirmPassword"
                    autocomplete="off"
                />
            </div>
        </div>
        <!-- Remove if statement once policy and tos are available -->
        <div class="form-group last" v-if="false">
            <label class="checkbox mb-0">
                <input type="checkbox" name="agree"/>
                <span class="mr-2"></span>
                <i18n path="AUTH.REGISTER.ACCEPT_TERMS" tag="label" class="mb-0">
                    <a href="#" target="_blank">{{ $t('AUTH.REGISTER.PRIVACY') }}</a>
                    <a href="#" target="_blank">{{ $t('AUTH.REGISTER.TOS') }}</a>
                </i18n>
            </label>
        </div>
        <div class="pb-lg-0 mb-5">
            <button
                id="st_submit_button"
                ref="st_submit_button"
                class="btn btn-primary font-size-h4 w-100"
            >
                {{ $t('AUTH.REGISTER.BUTTON') }}
            </button>
        </div>
        <div class="pb-lg-0 mb-5">
            <router-link :to="{ path: '/auth/signin' }">
                <button
                    type="button"
                    id="st_cancel_button"
                    class="btn btn-outline-primary font-size-h4 w-100"
                >
                    {{ $t('AUTH.REGISTER.HAS_ACCOUNT') }}
                </button>
            </router-link>
        </div>
    </form>
</template>
<script>
    import formValidation from '@/assets/plugins/formvalidation/dist/es6/core/Core';

    // FormValidation plugins
    import Trigger from '@/assets/plugins/formvalidation/dist/es6/plugins/Trigger';
    import Bootstrap from '@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap';
    import SubmitButton from '@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton';

    import KTUtil from '@/assets/js/components/util';

    import { LOGOUT, REGISTER } from '@/modules/auth/auth-store';
    import { mapActions, mapGetters } from 'vuex';
    import { strongPassword, validateEmailDomain } from '@/core/helpers/globalMethods';

    export default {
        name: 'RegisterForm',
        props: {
            registrationUserType: {
                type: String,
                default: 'citizen'
            }
        },
        data() {
            return {
                email: '',
                password: '',
                confirmPassword: ''
            };
        },
        computed: {
            ...mapGetters({
                blacklistedDomains: "shared/registrationBlacklistedDomains",
            }),
            passwordFieldHelpTooltip() {
                return this.$t('AUTH.REGISTER.PASSWORD_HELP');
            }
        },
        methods: {
            ...mapActions({
                register: `auth/${REGISTER}`,
                logout: `auth/${LOGOUT}`
            })
        },
        mounted() {
            const register_form = KTUtil.getById('st_register_form');

            this.fv = formValidation(register_form, {
                fields: {
                    email: {
                        validators: {
                            notEmpty: {
                                message: this.$t('AUTH.VALIDATION.REQUIRED')
                            },
                            callback: {
                                message: this.$t('AUTH.VALIDATION.NO_UPPER'),
                                callback: (input) => {
                                    return input.value === input.value.toLowerCase();
                                },
                            },
                            emailAddress: {
                                message: this.$t('AUTH.VALIDATION.EMAIL')
                            },
                            emailDomain: {
                                message: this.$t('AUTH.VALIDATION.INVALID_EMAIL_DOMAIN'),
                            },
                        }
                    },
                    password: {
                        validators: {
                            notEmpty: {
                                message: this.$t('AUTH.VALIDATION.REQUIRED')
                            },
                            checkPassword: {
                                message: this.$t('AUTH.VALIDATION.STRONG_PASSWORD')
                            }
                        }
                    },
                    confirmPassword: {
                        validators: {
                            notEmpty: {
                                message: this.$t('AUTH.VALIDATION.REQUIRED')
                            },
                            identical: {
                                compare: function() {
                                    return register_form.querySelector('[name="password"]').value;
                                },
                                message: this.$t('AUTH.VALIDATION.CONFIRM_PASSWORD')
                            }
                        }
                    },
                    // Uncomment section once policy and tos are available
                    // agree: {
                    //     validators: {
                    //         notEmpty: {
                    //             message: this.$t('AUTH.VALIDATION.AGREEMENT_REQUIRED')
                    //         }
                    //     }
                    // }
                },
                plugins: {
                    trigger: new Trigger(),
                    submitButton: new SubmitButton(),
                    bootstrap: new Bootstrap()
                }
            })
                .registerValidator('checkPassword', strongPassword)
                .registerValidator('emailDomain', () => validateEmailDomain(this.blacklistedDomains || []));

            this.fv.on('core.form.valid', () => {
                const email = this.$refs.email.value;
                const password = this.$refs.password.value;
                const registration_user_type = this.registrationUserType;

                // clear existing errors
                this.logout();

                // set spinner to submit button
                const submitButton = this.$refs['st_submit_button'];
                submitButton.classList.add('spinner', 'spinner-light', 'spinner-right');

                // dummy delay
                setTimeout(() => {
                    // send register request
                    this.register({ email, password, registration_user_type })
                        .then((data) => {
                            const { isEmailConfirmed } = data;
                            if (!isEmailConfirmed) {
                                this.$router.push({ name: 'verifyEmail' });
                            } else {
                                this.$router.push({ name: 'home' });
                            }
                        });

                    submitButton.classList.remove(
                        'spinner',
                        'spinner-light',
                        'spinner-right'
                    );
                }, 2000);
            });
        }
    };
</script>

<template>
    <auth-common-form form-type="change-password">
        <template #title>{{ $t('AUTH.CHANGE_PASSWORD.TITLE') }}</template>
        <template #subtitle>{{ $t('AUTH.CHANGE_PASSWORD.DESC') }}</template>
    </auth-common-form>
</template>

<script>
    import AuthCommonForm from '../components/AuthCommonForm';

    export default {
        name: 'ChangePassword',
        components: {
            AuthCommonForm,
        },
    };
</script>
